<template>
  <div class="login"></div>
</template>

<script>
import { setLangCode } from "@/services/collaboration.service";
import { versionApi } from "@/services/service-methods";
import { addTrace } from "@/services/logging";

const AppConfig = JSON.parse(localStorage.getItem("AppConfig"));

export default {
  name: "Login",
  created() {
    // get and set data in sessionstorage 
    const url = window.location.href;
    sessionStorage.setItem(
      "token",
      this.getParameterByName("access_token", url)
    );
    sessionStorage.setItem(
      "fullname",
      this.getParameterByName("fullname", url)
    );
    addTrace("User has successfully logged in!", 4);
    const StateParams = this.getParameterByName("state", url);
    versionApi(function (valid) {
      if (valid == true) {
        setLangCode(function () {
          if(StateParams && JSON.parse(StateParams).sharedId){
              window.location.href = AppConfig.BaseUrl +"entry/" + JSON.parse(StateParams).sharedId;
          }else{
            window.location.href = AppConfig.BaseUrl;
          }
        });
      }
    });
  },
  methods: {
    getParameterByName: function (name, url) {
      const regex = new RegExp("[?(&|#)]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return "";
      }
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
  },
};
</script>

